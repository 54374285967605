import React, { Component } from 'react';
import styles from './AboutPage.module.css';

class AboutPage extends Component {
  render() {
    return (
      <div className={styles.base}>
        about
      </div>
    )
  };
}

export default AboutPage;
